/**
 * @fileoverview Shared constants and enums used in the application
 * @readonly
 */

/**
 * @description Types to represent which action to take during role and assignment selection
 * @enum {number}
 */
export const SessionAction = {
  SELECT_ROLE: 1,
  CHANGE_ROLE: 2,
  SELECT_ASSIGNMENT: 3,
  CHANGE_ASSIGNMENT: 4,
  SELECT_ASSIGNMENT_AFTER_CHANGE_ROLE: 5,
};

export const PageTitle = {
  AIRTRAK_TEST: 'AirtrAK-Test',
  AIRTRAK_QA: 'AirtrAK-QA',
  AIRTRAK: 'AirtrAK',
};

/**
 * @description Types of values for the assignmentList filter
 * @enum {number}
 */
export const AssignmentFilter = {
  ALL_AIRCRAFT: 1,
  MY_AIRCRAFT: 2,
  ALL_FLIGHTS: 3,
  MY_FLIGHTS: 4,
};

/**
 * @description Types of values for the phase of flight filter
 * @enum {number}
 */
export const PhaseOfFlightFilter = {
  ALL_FLIGHTS: 1,
  PRE_FLIGHT: 2,
  TAXI_OUT: 3,
  EN_ROUTE: 4,
  TAXI_IN: 5,
  POST_FLIGHT: 6,
};

/**
 * @description Types to represent a flight's phase of flight
 * @enum {number}
 */
export const PhaseOfFlight = {
  UNKNOWN: 0,
  PRE_FLIGHT: 1,
  OUT: 2,
  OFF: 3,
  ON: 4,
  IN: 5,
  OFF_ONLY: 6,
  ON_ONLY: 7,
  IN_ONLY: 8,
  OFF_ON_ONLY: 9,
  OUT_ON_ONLY: 10,
  ON_IN_ONLY: 11,
  OUT_IN_ONLY: 12,
};

/**
 * @description Types of values for the airline filter
 * @enum {number}
 */
export const AirlineFilter = {
  ALL: 1,
  AS: 2,
  QX: 3,
  OO: 4,
};

/**
 * @description Default alert values that are not data-driven.
 * @enum {string}
 */
export const AlertFilter = {
  NO_FILTER: '--',
  ALL: 'All',
  SHORT_TURN_TIME: 'ShortTurnTime',
};

/**
 * @description Default sub-fleet values that are not data-driven.
 * @enum {string}
 */
export const SubFleetFilter = {
  NO_FILTER: '--',
  ALL: 'All',
};

/**
 * @description Types to represent which view to show on the flights page
 * @enum {number}
 */
export const FlightView = {
  GANTT_VIEW: 1,
  TABLE_VIEW: 2,
  SWAP_VIEW: 3,
};

/**
 * @description Types for irrop flight action menu items
 * @enum {string}
 */
export const IrropsMenuItemAction = {
  OPEN_URL: 'OpenUrl',
  TEAM_MESSAGING: 'TeamMessagingClick',
  CANCEL_FLIGHT: 'CancelFlight',
  REINSTATE_FLIGHT: 'ReinstateFlight',
  REROUTE_FLIGHT: 'RerouteFlight',
  REDISPATCH_FLIGHT: 'RedispatchFlight',
  BLOCK_TURN_BACK: 'BlockTurnBack',
  DIVERT_FLIGHT: 'DivertFlight',
  AIR_TURN_BACK: 'AirTurnBack',
  UPDATE_IRROPS_REASON: 'UpdateIrropsReason',
  ADD_CONTINUE_FLIGHT: 'AddContinueFlight',
};

/**
 * @description Types of Split.io feature flag treatment names
 * @enum {string}
 */
export const Treatment = {
  FLIGHT_DETAIL_PANE: 'FlightOps_AirTrak_FlightDetailPanel',
  FLIGHT_DETAIL_SUMMARY: 'FlightOps_AirTrak_Summary',
  FLIGHT_SWAP: 'FlightOps_AirTrak_Swap',
  FLIGHT_SWAP_SHOWWARNINGMODAL: 'FlightOps_AirTrak_ShowSwapWarningModal',
  FLIGHT_EQUIPMENT_CHANGE: 'FlightOps_AirTrak_EquipmentChangeTool',
  FLIGHT_FLIFO: 'FlightOps_AirTrak_FLIFO',
  DASHBOARD_ANALYTICS: 'FlightOps_AirTrak_Dashboard',
  DARK_MODE: 'FlightOps_AirTrak_ModernTheme',
  BETA_Label: 'FlightOps_AirTrak_BetaLabel',
  GANTT_VIRTUALIZATION: 'FlightOps_AirTrak_GanttVirtualization',
  TABLE_VIRTUALIZATION: 'FlightOps_AirTrak_TableVirtualization',
  DRAGGABLE_MODAL: 'FlightOps_AirTrak_DraggableModal',
  IRROPS_SESSIONID: 'FlightOps_AirTrak_SessionID',
  ADD_CONTINUE_FLIGHT: 'FlightOps_AirTrak_AddContinueFlight',
  DATE_PICKER_MAXDATE: 'FlightOps_AirTrak_DatePickerMaxDate',
  SEARCH_FLIGHTNUMBER: 'FlightOps_AirTrak_SearchFlightNumber',
  COLLAPSING_ROWS: 'FlightOps_AirTrak_CollapsingRows',
  SWAP_MODE_COLLAPSING_ROWS: 'FlightOps_AirTrak_SwapModeCollapsingRows',
  OPEN_FLIGHT_PANEL: 'FlightOps_AirTrak_OpenFlightPanel',
  SHORT_TURN_ALERTS: 'FlightOps_AirTrak_DisplayShortTurnAlertsPuckColor',
  TAIL_NUMBER_INDICATORS: 'FlightOps_AirTrak_DisplayTailNumberIndicators',
  MEL_MX_MESSAGES: 'FlightOps_AirTrak_MxMessages',
  MULTIPLE_VIEWS: 'FlightOps_AirTrak_MultipleViews',
  SAVABLE_CONFIGS: 'FlightOps_AirTrak_SavableConfigs',
  SCALING_BUTTON: 'FlightOps_AirTrak_ScalingModal',
  CREW_ON_FLIGHT: 'FlightOps_AirTrak_CrewOnFlight',
  OVERLAPPING_PUCKS: 'FlightOps_AirTrak_OverlappingPucks',
  SORT_MENU: 'FlightOps_AirTrak_SortMenu',
  BLOCK_PUCKS: 'FlightOps_AirTrak_BlockPucks',
  SWAP_WARNINGS_V2: 'FlightOps_AirTrak_SwapWarning_V2',
};

/**
 * @description Types of irregular operations codes
 * @enum {string}
 */
export const IrropsCode = {
  CANCELLED_FLIGHT: 'CXL',
  REINSTATE_FLIGHT: 'RIN',
  DIVERT_FLIGHT: 'DIV',
  REROUTE_FLIGHT: 'RRT',
  ADD_FLIGHT: 'ADD',
  RETURN_TO_GATE: 'RET',
  AIR_TURN_BACK: 'ATB',
  BLOCK_TURN_BACK: 'BTB',
  REDISPATCH_FLIGHT: 'RDX',
  EQUIPMENT_SWAP: 'EQT',
};

/**
 * @description Types to represent a FormData component
 * @enum {string}
 */
export const FormDataBrickType = {
  STRING: 'DATA',
  TIME: 'TIME',
  STATION_SINGLE: 'STATION_SINGLE',
  STATION_MULTIPLE: 'STATION_MULTIPLE',
};

/**
 * @description Types of session storage keys
 * @enum {string}
 */
export const SessionStorageKeys = {
  SORT_FILTER: 'aoc:sortFilter',
  FILTER: 'aoc:flightfilter',
  TABLE_SORT: 'aoc:savedSort',
  AUTO_REFRESH: 'aoc:autoRefresh',
  HIDE_CANCELLED_FLIGHTS: 'aoc:hideCancelledFlights',
};

/**
 * @description Types of auto refresh session storage keys
 * @enum {string}
 */
export const AutoRefreshStorageKeys = {
  GANTT: 'gantt_Refresh',
  FLIGHT_DETAIL_PANE: 'flight_detail_pane',
};

/**
 * @description All current AOC User Role names. Names must match the database entry.
 * @enum {string}
 */
export const RoleNames = {
  // AS
  AS_NOD: 'AS Network Operation Director',
  AS_SECTOR_MANAGER: 'AS Sector Manager',
  AS_SECTOR_SUPPORT: 'AS Sector Support',
  AS_ROUTER: 'AS Router',
  AS_MTX_CONTROL: 'AS Maintenance Control',
  AS_MTX_PLANNER: 'AS Maintenance Planner',
  AS_DISPATCHER: 'AS Dispatcher',
  AS_READ_ONLY: 'AS Read Only',
  // QX
  QX_FCM: 'QX Flight Control Manager',
  QX_MCO: 'QX Manager of Customer Operation',
  QX_ROUTER: 'QX Router',
  QX_MTX_CONTROL: 'QX Maintenance Control',
  QX_MTX_PLANNER: 'QX Maintenance Planner',
  QX_DISPATCHER: 'QX Dispatcher',
  QX_READ_ONLY: 'QX Read Only',
  // ITS
  SYSTEM_ADMIN: 'System Admin',
  AOC_DELIVERY_SUPPORT: 'AOC Delivery Support',
};

/**
 * @description All current AOC Entitlement names.  Names must match the database entry.
 * @enum {string}
 */
export const EntitlementNames = {
  IRREGULAROPS: 'Irregular Operations',
  CANCEL: 'Cancel',
  REINSTATE: 'Reinstate',
  DIVERT: 'Divert',
  REROUTE: 'Reroute',
  AIRTURNBACK: 'Air Turn Back',
  BLOCKTURNBACK: 'Block Turn Back (RET)',
  REDISPATCH: 'Redispatch',
  ADDFLIGHT: 'Add Flight',
  UPDATEIRROPSREASON: 'Update Irrops Reason',
  EDITWATCHFLIGHT: 'Edit Watch Flight',
  SUPPORTVALIDATION: 'Support Validation',
  SWAP: 'Swap',
  EQUIPMENTCHANGE: 'Equipment Change',
  MXMESSAGES: 'Mx Messages',
  FLIFO: 'FLIFO',
  OVERLAPPINGPUCKS: 'Overlapping Pucks',
  MELMXMESSAGES: 'MEL & Mx Messages',
};

/**
 * @description Irrops notification messages
 * @enum {string}
 */
export const IrropsModal_Notification = {
  NONE: '',
  ERROR: 'Must be after',
  BLOCKTIME_WARNING: 'Block time is more than 8 hours',
  WARNING: 'Warning: Short Turn Time',
  REFTIME_WARNING: 'Should be after',
};

/**
 * @description Irrop Flight Status Error Message
 * @enum {string}
 */
export const IrropFlightStatusErrorMessage = {
  ERROR: 'Flight status changed - cannot perform selected IrrOp.',
};

/**
 * @description Types of date formatters
 * @enum {string}
 */
export const DateFormat = {
  DEFAULT: 'YYYY-MM-DD',
  ISO: 'YYYY-MM-DDTHH:mm:ssZ',
  ISO_STANDARD: 'YYYY-MM-DDTHH:mm:ss',
};

/**
 * @description One REM in pixels
 * @enum {string}
 */
export const RemInPixels = {
  ONE_REM_IN_PX: 10,
};

/**
 * @description Types of puck components including flight and ground pucks
 * @enum {string}
 */
export const PuckType = {
  // Normal turn ground puck when the first in the line of flight
  GROUND_TURN_PRE: 'GROUND_TURN_PRE',
  // Normal turn ground puck following a flight puck or non-turn ground puck (i.e. OTS, Standby)
  GROUND_TURN_POST: 'GROUND_TURN_POST',
  // Out of service ground puck
  GROUND_OTS: 'GROUND_OTS',
  GROUND_UNSCHEDULED_OTS: 'GROUND_UNSCHEDULED_OTS',
  GROUND_SCHEDULED_OTS: 'GROUND_SCHEDULED_OTS',
  // Block ground puck
  GROUND_UNAVAILABLE: 'GROUND_UNAVAILABLE',
  // Standby aircraft ground puck
  GROUND_STANDBY: 'GROUND_STANDBY',
  GROUND_CANCELLED: 'GROUND_CANCELLED',
  FLIGHT_CANCELLED: 'FLIGHT_CANCELLED',
  FLIGHT: 'FLIGHT',
};

/**
 * @description Ground event types from the Ops360 database
 * @enum {string}
 */
export const GroundEventType = {
  MTX: 'MTX',
  STANDBY: 'STANDBY',
  SCHEDULED_OTS: 'SCHEDULED',
  UNSCHEDULED_OTS: 'UNSCHEDULED',
  UNAVAILABLE: 'UNAVAILABLE',
};

/**
 * @description Time Zone Identifier constants for use.  Add as-needed; Use only Canonical IANA Time Zone names.
 * @enum {string}
 */
export const TimeZones = {
  UTC: 'Etc/UTC',
  PDT: 'America/Los_Angeles',
};
/**
 * @description Types of Keyboard Key inputs and values
 * @enum {number}
 */
export const KeyCodes = {
  BACKSPACE: 8,
  DELETE: 46,
  UP_ARROW: 38,
  DOWN_ARROW: 40,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  ENTER: 13,
  SPACE: 32,
  TAB: 9,
};

/**
 * @description Types of theme mode
 * @enum {string}
 */
export const ThemeMode = {
  CLASSIC: 'classic',
  MODERN_LIGHT: 'modern-light',
  MODERN_DARK: 'modern-dark',
};

/**
 * @description Allowed puck sizes
 * @enum {string}
 */
export const PuckSize = {
  X_SMALL: 'xsmall',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  X_LARGE: 'xlarge',
};

/**
 * @description Predefined scaling options for a Gantt chart, allowing for dynamic adjustments to the chart's appearance.
 * @type {Object.<string, GanttScaleOption>}
 */

export const GanttScale = {
  xlarge: {
    ganttRowHeight: 4.8,
    puckHeight: 1.8,
  },
  large: {
    ganttRowHeight: 3.6,
    puckHeight: 1.6,
  },
  medium: {
    ganttRowHeight: 2.8,
    puckHeight: 1.4,
  },
  small: {
    ganttRowHeight: 2.2,
    puckHeight: 1.2,
  },
  xsmall: {
    ganttRowHeight: 1.7,
    puckHeight: 1,
  },
};
/**
 * @description Types of warnings
 * @enum {string}
 */
export const WarningTypes = {
  NONE: 'none',
  WARNING: 'warning',
  ERROR: 'error',
};

/**
 * @description Types of Irrops Modal Fields
 * @enum {string}
 */
export const IrropsModalFieldType = {
  IRROP_ETA: 'irropETA',
  STATIONS: 'stations',
  CONTINUATION_CHECKED: 'continuationChecked',
  CONTINUATION_TURNTIME: 'continuationTurnTime',
  CONTINUATION_ETD: 'continuationETD',
  CONTINUATION_ETA: 'continuationETA',
  STATION_CONTINUATION_ADD: 'stationContinuationAdd',
};

/**
 *  @description Out of Service Ground Event States
 *  @enum {string}
 */
export const OutOfServiceState = {
  ADVISORY: 'ADV',
  ESTIMATED_TIME_READY: 'ETR',
  COMPLETED: 'COMPLETED',
  COMPLETED_ETR: 'COMPLETED_ETR',
  UNKNOWN: 'UNKNOWN',
};

/**
 * @description Identifier for elements which use Persistance local storage to maintain scroll position.
 * @enum {string}
 */
export const ScrollLocalStorageElementId = {
  GANTT_CHART: 'aoc:scrollPositionGanttChart',
  FLIGHTLIST_TABLE: 'aoc:scrollPositionFlightListTable',
  LIGHTLIST_TABLE_VIRTUALIZER: 'aoc:scrollPositionFlightListTableVirtualizer',
  GANTT_SCALING: 'aoc:ganttScaling',
};

export const CrosshairSessionStorageId = {
  CROSSHAIR: 'aoc:crosshair',
};

/**
 * @description Time Zone Preference Identifier constants for use.  Add as-needed;
 * @enum {string}
 */
export const TimeZonePreference = {
  UTC: 'Etc/UTC',
  STATION_LOCAL: 'Station Local Time',
  SEA_LOCAL: 'SEA',
  DFW_LOCAL: 'DFW',
};

/**
 * @description Operational note tags
 * @enum {string}
 */
export const NoteTag = {
  WATCH_FLIGHT: 'WatchFlight',
  INFO_BY_DELAY: 'InfoByDelay',
};

/**
 * @description Operational note tags
 * @enum {string}
 */
export const AssignmentType = {
  FLIGHT: 'FLIGHT',
  AIRCRAFT: 'AIRCRAFT',
  ALL_FLIGHTS: 'ALL FLIGHTS',
  ALL_AIRCRAFT: 'ALL AIRCRAFT',
};

/**
 * @description Default text values for buttons .
 * @enum {string}
 */
export const ButtonText = {
  SUBMIT: 'Submit',
  SUBMITTING: 'Submitting...',
};

export const AssignmentLabels = {
  ALL_AIRCRAFT: 'All Aircraft',
  MY_AIRCRAFT: 'My Aircraft',
  ALL_FLIGHTS: 'All Flights',
  MY_FLIGHT: 'My Flights',
};

export const SummaryPanelMode = {
  MINI_SUMMARY: 'Mini Summary',
  EXPANDED_DETAIL: 'Expanded Detail',
};
/**
 * @description phase Of flight identifier constants for filter;
 * @enum {string}
 */
export const phaseOfFlightItems = [
  { key: PhaseOfFlightFilter.ALL_FLIGHTS, name: 'Select All', checked: true },
  { key: PhaseOfFlightFilter.PRE_FLIGHT, name: 'Planned', checked: true },
  { key: PhaseOfFlightFilter.TAXI_OUT, name: 'Taxi Out', checked: true },
  { key: PhaseOfFlightFilter.EN_ROUTE, name: 'Enroute', checked: true },
  { key: PhaseOfFlightFilter.TAXI_IN, name: 'Taxi In', checked: true },
  { key: PhaseOfFlightFilter.POST_FLIGHT, name: 'Completed', checked: true },
];

/**
 * @description airline identifier constants for filter;
 * @enum {string}
 */
export const airlineItems = [
  { key: AirlineFilter.ALL, name: 'Select All', checked: true },
  { key: AirlineFilter.AS, name: 'AS', checked: true },
  { key: AirlineFilter.QX, name: 'QX', checked: true },
  { key: AirlineFilter.OO, name: 'OO', checked: true },
];

export const airlineName = {
  OOAIRLINE: 'OO',
};

/**
 * @description assignment identifier constants for filter;
 * @enum {string}
 */
export const assignmentListItems = [
  { key: AssignmentFilter.ALL_AIRCRAFT, name: AssignmentLabels.ALL_AIRCRAFT, checked: false },
  { key: AssignmentFilter.MY_AIRCRAFT, name: AssignmentLabels.MY_AIRCRAFT, checked: false },
  { key: AssignmentFilter.ALL_FLIGHTS, name: AssignmentLabels.ALL_FLIGHTS, checked: false },
  { key: AssignmentFilter.MY_FLIGHTS, name: AssignmentLabels.MY_FLIGHT, checked: false },
];

/**
 * @description No data message constants
 * @enum {string}
 */
export const NoDataMessage = {
  FLIGHTS_ERROR: 'Unable to retrieve flight information at this time. Please refresh or contact ITS Service Desk.',
  FLIGHTS_AND_GROUND_EVENTS_ERROR:
    'Unable to retrieve flight and ground event information at this time. Please refresh or contact ITS Service Desk.',
  FILTER_YIELDS_NO_DATA: 'No data exists for this filter. Please adjust your values and try again.',
};

export const ApiErrorName = {
  SEAT_COUNT_DATA: 'SeatCountsData',
  DISPATCH_DESK_DATA: 'DispatchDeskData',
  GATE_DATA: 'GateData',
};

/**
 * @description Refetch page data constants
 * @enum {string}
 */
export const RefetchPageData = {
  GANTT: 'GANTT',
  FLIGHT_LIST_TABLE: 'FLIGHT_LIST_TABLE',
  FLIGHT_DETAIL_PANE: 'FLIGHT_DETAIL_PANE',
  SUMMARY_MINI: 'SUMMARY_MINI',
  SUMMARY_FLIGHT_DETAIL: 'SUMMARY_DETAIL',
  SUMMARY_ACTIVITY_LOG: 'SUMMARY_ACTIVITY_LOG',
  FLIGHT_DETAIL_PANE_ACTIVITY_LOG: 'FLIGHT_DETAIL_PANE_ACTIVITY_LOG',
  USER_CONFIGURATION_PAGE: 'USER_CONFIGURATION_PAGE',
};

/**
 * @description Resize window types
 * @enum {string}
 */
export const ResizeType = {
  RIGHT: 'RIGHT',
  LEFT: 'LEFT',
  BOTTOM: 'BOTTOM',
  TOP: 'TOP',
  TOP_RIGHT: 'TOP_RIGHT',
  TOP_LEFT: 'TOP_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
  BOTTOM_LEFT: 'BOTTOM_LEFT',
};

/**
 * @description Aircraft Certificate Status
 * @enum {string}
 */
export const AircraftCertificateStatus = {
  AIRCRAFT_CERTIFICATE: 1,
  TEMPORARY_AIRCRAFT_CERTIFICATE: 2,
  MISSING_AIRCRAFT_CERTIFICATE: 3,
};

/**
 * @description Types for a view window in multiple views
 * @enum {string}
 */
export const ViewWindowType = {
  SINGLE: 'SINGLE',
  MULTI: 'MULTI',
  SWAP_MODE: 'SWAP_MODE',
  MINIMIZED: 'MINIMIZED',
  MAXIMIZED: 'MAXIMIZED',
};

/**
 * @description Keys for summary panel tabs
 * @enum {string}
 */
export const ActiveKeys = {
  Flight: 'Flight',
  Alerts: 'Alerts',
  ActivityLog: 'ActivityLog',
  Passenger: 'Passenger',
  Aircraft: 'Aircraft',
  Crew: 'Crew',
};

/**
 * @description Titles for a view window in multiple views
 * @enum {string}
 */
export const ViewWindowTitle = {
  DEFAULT: "Today's Rotation",
};

Object.freeze(SessionAction);
Object.freeze(AssignmentFilter);
Object.freeze(PhaseOfFlightFilter);
Object.freeze(AirlineFilter);
Object.freeze(FlightView);
Object.freeze(AlertFilter);
Object.freeze(IrropsMenuItemAction);
Object.freeze(Treatment);
Object.freeze(IrropsCode);
Object.freeze(FormDataBrickType);
Object.freeze(SessionStorageKeys);
Object.freeze(IrropsModal_Notification);
Object.freeze(IrropFlightStatusErrorMessage);
Object.freeze(RoleNames);
Object.freeze(EntitlementNames);
Object.freeze(PuckType);
Object.freeze(GroundEventType);
Object.freeze(TimeZones);
Object.freeze(KeyCodes);
Object.freeze(ThemeMode);
Object.freeze(PuckSize);
Object.freeze(WarningTypes);
Object.freeze(IrropsModalFieldType);
Object.freeze(OutOfServiceState);
Object.freeze(ScrollLocalStorageElementId);
Object.freeze(TimeZonePreference);
Object.freeze(NoteTag);
Object.freeze(AutoRefreshStorageKeys);
Object.freeze(AssignmentType);
Object.freeze(AssignmentLabels);
Object.freeze(phaseOfFlightItems);
Object.freeze(airlineItems);
Object.freeze(assignmentListItems);
Object.freeze(SummaryPanelMode);
Object.freeze(NoDataMessage);
Object.freeze(ApiErrorName);
Object.freeze(airlineName);
Object.freeze(RefetchPageData);
Object.freeze(AircraftCertificateStatus);
Object.freeze(ViewWindowType);
Object.freeze(ResizeType);
Object.freeze(ActiveKeys);
Object.freeze(ViewWindowTitle);
