import { useFilterStore } from '../../hooks/useFilterStore/useFilterStore';
import { useUserPreferencesData } from '../../contexts/UserPreferencesContext/UserPreferencesContext';
import { useHideStore } from '../../hooks/useHideCancelledFlightsStore/useHideCancelledFlightsStore';
import { useWindowManagerStore } from '../../hooks/useWindowManagerStore/useWindowManagerStore';
import { useSelector } from 'react-redux';
import { TimeZones } from '../../lib/constants';
import dayjs from 'dayjs';

const ConfigurationMapper = () => {
  const { filter } = useFilterStore();
  const { state: userPreferencesState } = useUserPreferencesData();
  const { hideCancelledFlights } = useHideStore();
  const viewMetaData = useWindowManagerStore();

  const viewWindowData = useSelector((state) => state.viewWindowData);

  const mapConfigFilter_ToStateFilter = (filter) => {
    const timeZone = filter.timezone ?? TimeZones.UTC;
    const startDateCalc = dayjs().utc().add(filter.startDateDaysFromToday, 'day').format('MM-DD-YYYY');
    const endDateCalc = dayjs().utc().add(filter.endDateDaysFromToday, 'day').format('MM-DD-YYYY');
    return {
      startDate: dayjs(startDateCalc).tz(timeZone, true).startOf('day'),
      endDate: dayjs(endDateCalc).tz(timeZone, true).endOf('day'),
      flight: filter?.flight ?? [],
      aircraft: filter?.aircraft ?? [],
      origin: filter?.origin ?? [],
      destination: filter?.destination ?? [],
      assignmentList: toNumberArray(filter?.assignmentList) ?? [],
      flightPhase: toNumberArray(filter?.flightPhase) ?? [],
      airline: toNumberArray(filter?.airline) ?? [],
      alerts: filter?.alerts ?? [],
      fleets: filter?.fleets ?? [],
      timezone: timeZone,
    };
  };
  const toStringArray = (arr) => {
    return arr.map((a) => a.toString());
  };
  const toNumberArray = (arr) => {
    return arr.map((a) => parseInt(a));
  };
  const mapStateFilter_ToConfigFilter = (filter) => {
    const startDateDaysFromToday = dayjs(dayjs(filter.startDate).format('MM-DD-YYYY')).diff(
      dayjs().format('MM-DD-YYYY'),
      'day',
      false,
    );

    const endDateDaysFromToday = dayjs(dayjs(filter.endDate).format('MM-DD-YYYY')).diff(
      dayjs().format('MM-DD-YYYY'),
      'day',
      false,
    );

    return {
      // filter
      startDateDaysFromToday: startDateDaysFromToday,
      endDateDaysFromToday: endDateDaysFromToday,
      flight: toStringArray(filter.flight),
      aircraft: filter.aircraft,
      origin: filter.origin,
      destination: filter.destination,
      assignmentList: toStringArray(filter.assignmentList),
      flightPhase: toStringArray(filter.flightPhase),
      airline: toStringArray(filter.airline),
      alerts: toStringArray(filter.alerts),
      fleets: filter.fleets,
      timezone: filter.timezone,
    };
  };

  const MapSingleViewState_ToUserConfig = () => {
    const singleViewWindow = {
      id: 0,
      viewName: 'Default View',
      viewType: '1',
      position: {
        x: 0,
        y: 0,
      },
      size: {
        width: 800,
        height: 800,
      },
      viewOrder: 0,
      isMinimized: false,
      isMaximized: false,
      isDraggedMinimized: false,
      hideCancelledFlights: hideCancelledFlights, // Hide Cancelled Flights
      filter: mapStateFilter_ToConfigFilter(filter),
      ganttViewConfiguration: {
        // gantt view configuration
        hoursBefore: userPreferencesState?.ganttViewScaling?.hoursBefore ?? null,
        hoursAfter: userPreferencesState?.ganttViewScaling?.hoursAfter ?? null,
        numberOfAircrafts: userPreferencesState?.ganttViewScaling?.numberOfAircrafts ?? null,
        timelineHours: userPreferencesState?.timelineHours?.timelineHours ?? null,
        puckSize: userPreferencesState?.puckSize?.puckSize ?? null,
      },
    };
    return {
      browserSize: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      viewWindows: [singleViewWindow],
    };
  };

  const MapUserConfig_ToSingleViewState = (userConfig) => {
    const singleViewWindow = userConfig?.viewWindows[0];
    if (!singleViewWindow) {
      return null;
    }
    const filter = singleViewWindow.filter;
    const ganttViewConfiguration = singleViewWindow.ganttViewConfiguration;
    return {
      hideCancelledFlightsReducer: {
        hideCancelledFlights: singleViewWindow.hideCancelledFlights,
      },
      filterReducer: {
        previousFilter: null,
        isDefaultFilter: false,
        isSwapModeFilter: false,
        defaultAssignmentFilter: null,
        timezone: filter?.timezone ?? TimeZones.UTC,
        operatingAirlineByRole: null,
        filter: mapConfigFilter_ToStateFilter(filter),
      },
      viewConfigurationReducer: {
        hoursBefore: ganttViewConfiguration?.hoursBefore,
        hoursAfter: ganttViewConfiguration?.hoursAfter,
        numberOfAircrafts: ganttViewConfiguration?.numberOfAircrafts,
        timelineHours: ganttViewConfiguration?.timelineHours,
        puckSize: ganttViewConfiguration?.puckSize,
      },
    };
  };

  const MapMultiViewState_ToUserConfig = () => {
    const { viewWindows, viewOrder, draggedMinimizedViews, maximizedViews, minimizedViews } = viewMetaData;

    const multiViewData =
      viewWindowData?.map((viewWindowDataItem) => {
        const viewWindow = viewWindows?.find((x) => x.id === viewWindowDataItem.id) || {};

        const { id, hideCancelledFlightsReducer, filterReducer } = viewWindowDataItem;

        const { viewTitle = '', viewType = '', viewPosition = { x: 0, y: 0 } } = viewWindow;

        return {
          id,
          viewName: viewTitle ?? `view - ${id}`,
          viewType: viewType.toString(),
          position: {
            x: viewPosition.x,
            y: viewPosition.y,
          },
          size: {
            width: 800,
            height: 800,
          },
          viewOrder: viewOrder.indexOf(id),
          isMinimized: minimizedViews.includes(id),
          isMaximized: maximizedViews.includes(id),
          isDraggedMinimized: draggedMinimizedViews.includes(id),
          hideCancelledFlights: hideCancelledFlightsReducer?.hideCancelledFlights,
          filter: mapStateFilter_ToConfigFilter(filterReducer?.filter),
          ganttViewConfiguration: {
            hoursBefore: userPreferencesState?.ganttViewScaling?.hoursBefore ?? null,
            hoursAfter: userPreferencesState?.ganttViewScaling?.hoursAfter ?? null,
            numberOfAircrafts: userPreferencesState?.ganttViewScaling?.numberOfAircrafts ?? null,
            timelineHours: userPreferencesState?.timelineHours?.timelineHours ?? null,
            puckSize: userPreferencesState?.puckSize?.puckSize ?? null,
          },
        };
      }) || [];

    return {
      browserSize: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      viewWindows: multiViewData,
    };
  };

  const MapUserConfig_ToMultipleViewState = (userConfig) => {
    let viewWindows = [];
    let viewOrder = [];
    let minimizedViews = [];
    let maximizedViews = [];
    let draggedMinimizedViews = [];

    let viewWindowData = [];

    userConfig?.viewWindows.forEach((viewWindow) => {
      if (viewWindow.isMinimized) {
        minimizedViews.push(viewWindow.id);
      }
      if (viewWindow.isMaximized) {
        maximizedViews.push(viewWindow.id);
      }
      if (viewWindow.isDraggedMinimized) {
        draggedMinimizedViews.push(viewWindow.id);
      }

      // add viewWindow id to viewOrder array to maintain the order of the windows
      if (viewOrder.length > viewWindow.viewOrder) {
        viewOrder.splice(viewWindow.viewOrder, 0, viewWindow.id);
      } else {
        viewOrder.push(viewWindow.id);
      }

      viewWindows.push({
        id: viewWindow.id,
        viewTitle: viewWindow.viewName,
        viewType: viewWindow.viewType,
        viewPosition: {
          x: viewWindow.position.x,
          y: viewWindow.position.y,
        },
        viewSize: {
          width: viewWindow.size.width,
          height: viewWindow.size.height,
        },
      });

      let filter = viewWindow.filter;
      let ganttViewConfiguration = viewWindow.ganttViewConfiguration;

      viewWindowData.push({
        id: viewWindow.id,
        hideCancelledFlightsReducer: {
          hideCancelledFlights: viewWindow.hideCancelledFlights,
        },
        filterReducer: {
          previousFilter: null,
          isDefaultFilter: false,
          isSwapModeFilter: false,
          defaultAssignmentFilter: null,
          timezone: filter?.timezone ?? TimeZones.UTC,
          operatingAirlineByRole: null,
          filter: mapConfigFilter_ToStateFilter(filter),
        },
        viewConfigurationReducer: {
          hoursBefore: ganttViewConfiguration?.hoursBefore,
          hoursAfter: ganttViewConfiguration?.hoursAfter,
          numberOfAircrafts:
            ganttViewConfiguration?.numberOfAircrafts == undefined ? 0 : ganttViewConfiguration?.numberOfAircrafts,
          timelineHours: ganttViewConfiguration?.timelineHours,
          puckSize: ganttViewConfiguration?.puckSize,
        },
      });
    });

    return {
      viewManagerReducer: {
        viewWindows: viewWindows,
        viewOrder: viewOrder,
        minimizedViews: minimizedViews,
        maximizedViews: maximizedViews,
        draggedMinimizedViews: draggedMinimizedViews,
      },
      viewWindowData: viewWindowData,
    };
  };

  return {
    MapSingleViewState_ToUserConfig,
    MapUserConfig_ToSingleViewState,
    MapUserConfig_ToMultipleViewState,
    MapMultiViewState_ToUserConfig,
  };
};

export default ConfigurationMapper;
