import { useEffect, useState } from 'react';
import {
  AssignmentFilter,
  PhaseOfFlightFilter,
  AirlineFilter,
  SubFleetFilter,
  PuckType,
  AlertFilter,
} from '../../../lib/constants';
import { getAirlineName } from '../../../lib/displayUtils';
import { getPhaseOfFlightFilter, deepCopy } from '../../../lib/utils';
import { checkOverlap } from '../../../lib/dateTimeUtils';
import { setLineOfFlightRowLevels } from '../../../components/ManageOperationsPage/GanttChart/ganttHelpers';
import isEqual from 'lodash/isEqual';
import { SortActions } from '../../../redux/actionTypes';
import lodashOrderBy from 'lodash/orderBy';
/**
 * Returns the gantt config for swap mode
 * @param {Object} ganttConfig
 * @param {Array} swapFlightLegs
 * @returns
 */
export const getSwapModeGanttConfig = (ganttConfig, swapFlightLegs) => {
  // Need to deep copy to avoid mutating gantt config when performing swaps
  const ganttConfigCopy = deepCopy(ganttConfig);

  // Reconcile swap flight legs store into existing gantt config
  swapFlightLegs.forEach((swapFlightLeg) => {
    try {
      const {
        aircraftRegistration,
        swapAircraftRegistration,
        flightNumber,
        departureCount,
        actualOrigin,
        actualDestination,
        scheduledOperatingDateUTC,
      } = swapFlightLeg;

      const actualLineOfFlight = ganttConfigCopy[aircraftRegistration];
      const swapLineOfFlight = ganttConfigCopy[swapAircraftRegistration];

      // Find the index of the swap leg in the list of scheduled pucks
      const oldPuckIndex = actualLineOfFlight.scheduled.findIndex((puck) => {
        return (
          puck.puckType === PuckType.FLIGHT &&
          puck.flightPuckData.flightNumber === flightNumber &&
          puck.flightPuckData.departureCount === departureCount &&
          puck.flightPuckData.orig === actualOrigin &&
          puck.flightPuckData.dest === actualDestination &&
          puck.flightPuckData.departureDate === scheduledOperatingDateUTC
        );
      });

      if (oldPuckIndex !== -1) {
        const swapPuckData = JSON.parse(JSON.stringify(actualLineOfFlight.scheduled[oldPuckIndex]));

        swapPuckData.flightPuckData.isSwapFlightLeg = true;
        swapPuckData.flightPuckData.aircraft = swapAircraftRegistration;

        // Remove flight leg from actual aircraft
        actualLineOfFlight.scheduled.splice(oldPuckIndex, 1);

        const groundStandbyPuckIndex = swapLineOfFlight.scheduled.findIndex((puck, index) => {
          if (puck.puckType !== PuckType.GROUND_STANDBY) {
            // Skip flight pucks
            return false;
          }
          return checkOverlap(
            swapPuckData.flightPuckData.etd,
            swapPuckData.flightPuckData.eta,
            puck.groundPuckData.start,
            puck.groundPuckData.end,
          );
        });

        if (groundStandbyPuckIndex !== -1) {
          // Remove ground event puck from swap aircraft
          swapLineOfFlight.scheduled.splice(groundStandbyPuckIndex, 1);
        }

        // Add flight leg to new swap aircraft
        swapLineOfFlight.scheduled.push(swapPuckData);

        // Recalculate puck row levels
        setLineOfFlightRowLevels(actualLineOfFlight);
        setLineOfFlightRowLevels(swapLineOfFlight);
      }
    } catch (error) {
      console.error('Unable to reconcile swap flight leg into gantt chart', swapFlightLeg, error);
    }
  });

  return ganttConfigCopy;
};

/**
 * Returns the gantt config with canceled flights hidden
 * @param {Object} ganttConfig
 * @returns
 */
export const getHideCancelledFlightsGanttConfig = (ganttConfig) => {
  const aircraftList = Object.keys(ganttConfig);
  return aircraftList.reduce((result, aircraft) => {
    const lineOfFlightCopy = deepCopy(ganttConfig[aircraft]);
    if (lineOfFlightCopy.canceled?.length) {
      // Has canceled flights to hide
      if (lineOfFlightCopy.scheduled.length) {
        // After hiding canceled flights, there are still scheduled flights
        lineOfFlightCopy.canceled = [];
        setLineOfFlightRowLevels(lineOfFlightCopy);
        result[aircraft] = lineOfFlightCopy;
      }
    } else {
      // No cancel flights to hide
      result[aircraft] = lineOfFlightCopy;
    }
    return result;
  }, {});
};

/**
 * Returns the gantt config with the filter applied
 * @param {Object} ganttConfig
 * @param {Object} filter
 * @param {Object} activeAssignments
 * @returns
 */
export const getFilteredGanttConfig = (ganttConfig, filter, activeAssignments) => {
  const aircraftList = Object.keys(ganttConfig);
  return aircraftList.reduce((result, aircraft) => {
    const lineOfFlight = ganttConfig[aircraft];
    if (filterLineOfFlight(lineOfFlight, filter, activeAssignments)) {
      result[aircraft] = deepCopy(lineOfFlight);
    }
    return result;
  }, {});
};

const SortActionsMapping = {
  [SortActions.DEFAULT_PRIORITY]: 'defaultSortedPriority',
  [SortActions.ETD_PRIORITY]: 'etdSortedPriority',
  [SortActions.ETA_PRIORITY]: 'etaSortedPriority',
};

export const getSortedGanttConfig = (ganttConfig, sortFilter = SortActions.DEFAULT_PRIORITY) => {
  const sortProperty = SortActionsMapping[sortFilter];
  const sortedAircraftList = lodashOrderBy(ganttConfig, [sortProperty], ['asc']);

  return sortedAircraftList.reduce((result, lineOfFlight) => {
    const aircraft = lineOfFlight.aircraft;
    result[aircraft] = deepCopy(lineOfFlight);
    return result;
  }, {});
};

/**
 * Returns the flight puck data with flightLegKey from an aircraft
 * @param {Object} ganttConfig
 * @param {number} key
 * @param {string} aircraft
 * @returns
 */
export const getFlightPuckDataByKey = (ganttConfig, key, aircraft) => {
  const lof = ganttConfig[aircraft];
  if (!lof || !lof.scheduled) return {};
  let puckData = lof.scheduled.find((flight) => flight?.flightPuckData?.flightLegKey == key);
  return puckData?.flightPuckData;
};

export const useFlightNumberOverflow = (flightNumberRef) => {
  const [showFlightNumber, setShowFlightNumber] = useState(true);

  useEffect(() => {
    const timerRef = setTimeout(() => {
      const element = flightNumberRef?.current;
      if (element && element.scrollWidth > element.clientWidth) {
        setShowFlightNumber(false);
      } else {
        setShowFlightNumber(true);
      }
    }, 1000);

    return () => clearTimeout(timerRef);
  }, []);

  return { showFlightNumber, flightNumberRef };
};

export const isNotEqual = (value1, value2) => {
  return !isEqual(value1, value2);
};

/**
 * Returns true if the line of flight passes the filter
 * @param {Object} lineOfFlight - line of flight data
 * @param {Object} filter - filter object
 * @param {Object} activeAssignments - the user's active assignments
 */
export const filterLineOfFlight = (lineOfFlight, filter, activeAssignments) => {
  const { aircraft, airline, fleetType, scheduled, canceled } = lineOfFlight;

  // TODO - remove this after US-957955 which fixes nulls
  const canceledList = canceled ?? [];

  // First, filter by aircraft properties
  // Important: keep aircraft property filters at the top to short-circuit
  // the evaluation and stop unnecessary iterations into the individual pucks
  if (
    !(filterByAirline(filter, airline) && filterByAircraft(filter, aircraft) && filterBySubfleet(filter, fleetType))
  ) {
    return false;
  }

  // Then, filter by individual flight and ground puck propeties
  const filterByPuckDataCallback = (puckData) => {
    return (
      filterByAssignment(filter, puckData, aircraft, activeAssignments) &&
      filterByFlightNumber(filter, puckData) &&
      filterByPhaseOfFlight(filter, puckData) &&
      filterByAlerts(filter, puckData) &&
      filterByOriginDestination(filter, puckData)
    );
  };

  const hasMatchingScheduledPuck = scheduled.some(filterByPuckDataCallback);
  const hasMatchingCanceledPuck = canceledList.some(filterByPuckDataCallback);

  return hasMatchingScheduledPuck || hasMatchingCanceledPuck;
};

/**
 * Airline filter
 * Returns true if there is no airline filter or if the airline filter includes the airline
 * @param {Object} filter
 * @param {String} airline
 * @returns
 */
const filterByAirline = (filter, airline) => {
  if (!filter.airline?.length || filter.airline?.includes(AirlineFilter.ALL)) {
    return true;
  }

  return filter.airline.some((a) => getAirlineName(a) === airline);
};

/**
 * Aircraft filter
 * Returns true if there is no aircraft filter or if the aircraft filter includes the aircraft
 * @param {Object} filter
 * @param {String} aircraft
 * @returns
 */
const filterByAircraft = (filter, aircraft) => {
  if (!filter.aircraft?.length) {
    return true;
  }

  return filter.aircraft.includes(aircraft);
};

/**
 * Subfleet filter
 * Returns true if there is no subfleet filter or if the subfleet filter includes the subfleet
 * @param {Object} filter
 * @param {String} subfleetType
 * @returns
 */
const filterBySubfleet = (filter, subfleetType) => {
  if (!filter.fleets?.length || filter.fleets?.includes(SubFleetFilter.ALL)) {
    return true;
  }

  return filter.fleets.includes(subfleetType);
};

/**
 * Assignment filter
 * Returns true if the airline filter is ALL AIRCRAFT or if line of flight contains an active assignment
 * @param {Object} filter
 * @param {Object} puckData
 * @param {String} aircraft
 * @param {Object} activeAssignments - the user's active assignments
 * @returns
 */
const filterByAssignment = (filter, puckData, aircraft, activeAssignments) => {
  const assignmentFilter = filter?.assignmentList;

  if (!assignmentFilter?.length || assignmentFilter?.includes(AssignmentFilter.ALL_AIRCRAFT)) {
    return true;
  }

  const isFlightPuck = puckData.puckType === PuckType.FLIGHT || puckData.puckType === PuckType.FLIGHT_CANCELLED;

  let result = false;

  if (assignmentFilter.includes(AssignmentFilter.ALL_FLIGHTS)) {
    result |= isFlightPuck;
  }

  if (assignmentFilter.includes(AssignmentFilter.MY_AIRCRAFT)) {
    const myAircraft = activeAssignments?.aircraft ?? [];
    result |= myAircraft.includes(aircraft);
  }

  if (assignmentFilter.includes(AssignmentFilter.MY_FLIGHTS)) {
    const myFlights = activeAssignments?.flightLegKeys ?? [];
    result |= isFlightPuck && myFlights.includes(puckData.flightPuckData.flightLegKey);
  }

  return result;
};

/**
 * Flight number filter
 * Returns true if there is no flight filter or if the flight filter includes one of the flights in this line of flight
 * @param {Object} filter
 * @param {Object} puckData
 * @returns
 */
const filterByFlightNumber = (filter, puckData) => {
  if (!filter.flight.length) {
    return true;
  }

  const isFlightPuck = puckData.puckType === PuckType.FLIGHT || puckData.puckType === PuckType.FLIGHT_CANCELLED;
  return isFlightPuck && filter.flight.includes(puckData.flightPuckData.flightNumber.toString());
};

/**
 * Phase of Flight Filter
 * Returns true if there is no phase of flight filter or if the phase of flight filter includes one of the phases of flight in this line of flight
 * @param {Object} filter
 * @param {Array} scheduled - list of scheduled puck configs
 * @returns
 */
const filterByPhaseOfFlight = (filter, puckData) => {
  if (!filter.flightPhase.length || filter.flightPhase.includes(PhaseOfFlightFilter.ALL_FLIGHTS)) {
    return true;
  }

  const isFlightPuck = puckData.puckType === PuckType.FLIGHT || puckData.puckType === PuckType.FLIGHT_CANCELLED;
  return isFlightPuck && filter.flightPhase.includes(getPhaseOfFlightFilter(puckData.flightPuckData));
};

/**
 * Alerts Filter
 * @param {Object} filter
 * @param {Object} puckData
 * @returns
 */
const filterByAlerts = (filter, puckData) => {
  if (!filter.alerts.length || filter.alerts.includes(AlertFilter.NO_FILTER)) {
    return true;
  }

  const isFlightPuck = puckData.puckType === PuckType.FLIGHT || puckData.puckType === PuckType.FLIGHT_CANCELLED;
  return isFlightPuck && puckData?.flightPuckData?.hasShortTurnAlert;
};

/**
 * Origin and Destination Filter
 * Returns true if there is no origin or destination filter or if the origin or destination filter includes one of the stations in this line of flight
 * When a station is present in both the origin and destination, we use the station filter logic, which filters by any ins or out at that station.
 * @param {Object} filter
 * @param {Object} puckData
 * @returns
 */
const filterByOriginDestination = (filter, puckData) => {
  if (!filter.origin.length && !filter.destination.length) {
    return true;
  }

  const isFlightPuck = puckData.puckType === PuckType.FLIGHT || puckData.puckType === PuckType.FLIGHT_CANCELLED;
  const isGroundPuck = puckData.puckType === PuckType.GROUND_STANDBY || puckData.puckType === PuckType.GROUND_OTS;

  // When a station is present in both the origin and destination filter,
  // we use the station filter logic, which filters by any ins or out at that station
  const stations = filter.origin.filter((o) => filter.destination.includes(o));
  const origins = filter.origin.filter((o) => !stations.includes(o));
  const destinations = filter.destination.filter((d) => !stations.includes(d));

  let hasMatchingStation = false;
  let hasMatchingOrigin = false;
  let hasMatchingDestination = false;

  if (isFlightPuck) {
    const { orig, dest } = puckData.flightPuckData;

    // Is a station
    hasMatchingStation = stations.some((s) => s === orig || s === dest);
    // Or no destination filter, but needs a matching origin
    hasMatchingOrigin = origins.includes(orig) && !destinations.length;
    // Or no origin filter, but needs a matching destination
    hasMatchingDestination = destinations.includes(dest) && !origins.length;
    // Or has a matching origin and destination
    const hasMatchingRoute = origins.includes(orig) && destinations.includes(dest);

    return hasMatchingStation || hasMatchingOrigin || hasMatchingDestination || hasMatchingRoute;
  } else if (isGroundPuck) {
    const { groundEventStation } = puckData.groundPuckData;

    // Is a station
    hasMatchingStation = stations.includes(groundEventStation);
    // Or no destination filter, but needs a matching origin
    hasMatchingOrigin = origins.includes(groundEventStation) && !destinations.length;
    // Or no origin filter, but needs a matching destination
    hasMatchingDestination = destinations.includes(groundEventStation) && !origins.length;

    return hasMatchingStation || hasMatchingOrigin || hasMatchingDestination;
  }

  return false;
};

/**
 * Formats the gantt config query data
 * @param {bool} filter
 * @param {bool} puckData
 * @param {Object} data
 * @returns
 */
export const createGanttConfigQueryData = (isLoading, isSuccess, data) => {
  if (isLoading) {
    return {
      loading: true,
      ganttConfig: {},
      errors: {
        flights: false,
        groundEvents: false,
      },
    };
  } else if (isSuccess && data) {
    const { lineOfFlights, errors } = data;
    return {
      loading: false,
      ganttConfig: lineOfFlights,
      errors: {
        flights: errors.some((e) => e.errorName === 'FlightLegData'),
        groundEvents: errors.some((e) => e.errorName === 'GroundEventsData'),
      },
    };
  } else {
    return {
      loading: false,
      ganttConfig: {},
      errors: {
        flights: true,
        groundEvents: true,
      },
    };
  }
};
