import { ViewConfigurationAction } from '../actionTypes';
// initial state values
const initialState = {
  puckSize: 'large',
  timelineHours: 48,
  numberOfAircrafts: 45,
  hoursAfter: 32,
  hoursBefore: 4,
};

/**
 * Given the previous state and action payload return the new state for config mode
 * @returns - the initial state for the config store
 */

const viewConfigurationReducer = (state = initialState, action) => {
  // graduate to SWITCH statement when there are more actiontypes available for this reducer
  switch (action.type) {
    case ViewConfigurationAction.UPDATE_VIEWCONFIGURATION:
      return {
        ...state,
        ...action?.payload,
      };
    case ViewConfigurationAction.UPDATE_NUMBEROFAIRCRAFTS:
      return {
        ...state,
        numberOfAircrafts: action?.payload?.numberOfAircrafts ?? initialState.numberOfAircrafts,
      };
    case ViewConfigurationAction.UPDATE_HOURSAFTER:
      return {
        ...state,
        hoursAfter: action?.payload?.hoursAfter ?? initialState.hoursAfter,
      };
    case ViewConfigurationAction.UPDATE_HOURSBEFORE:
      return {
        ...state,
        hoursBefore: action?.payload?.hoursBefore ?? initialState.hoursBefore,
      };
    case ViewConfigurationAction.UPDATE_PUCKSIZE:
      return {
        ...state,
        puckSize: action?.payload?.puckSize ?? initialState.puckSize,
      };
    default:
      return state;
  }
};

export default viewConfigurationReducer;
