import { useVirtualizer, defaultRangeExtractor } from '@tanstack/react-virtual';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import usePrevious from '../../../../hooks/usePrevious/usePrevious';
import PropTypes from 'prop-types';
import {
  useFocusedFlightLegDispatch,
  useFocusedFlightLegStore,
} from '../../../../hooks/useFocusedFlightLegStore/useFocusedFlightLegStore';
import { useFlightPuckRefStore } from '../../../../hooks/useFlightPuckRefStore/useFlightPuckRefStore';
import { useUserPreferencesData } from '../../../../contexts/UserPreferencesContext/UserPreferencesContext';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext/FeatureFlagContext';
import { Treatment } from '../../../../lib/constants';
import { useGanttConfig } from '../../../../hooks/useGanttConfig/useGanttConfig';
import { useSelectedItemStoreWithEqualComparator } from '../../../../hooks/useSelectedItemStore/useSelectedItemStore';
import isEqual from 'lodash/isEqual';
/**
 * The GanttChartVirtualier is a container for virtualizing data (lines of flight)
 * for the gantt chart.
 * @param {Object} props -
 *   count - the total number of rows to be virtualized
 *   renderFn - callback function for rendering a given row
 *   estimatedRowHeightFn - callback function to get the height of a given row
 *   getItemKeyFn - callback function to get the key of a given row
 *   scrollToOffset - initial scrollTop value for the container
 *   scrollElementRef - reference to the scrolling container element
 *   id - unique name for virtualizer
 *   dataCyTag - data-cy test id
 */
const GanttChartVirtualizer = (props) => {
  const {
    count,
    renderFn,
    estimateRowHeightFn,
    getItemKeyFn,
    scrollToOffset,
    scrollElementRef,
    id,
    dataCyTag,
    scrollToFocusedFlightLegIndex,
    showRows,
  } = props;

  const prevRef = usePrevious(scrollElementRef);
  const visibleRangeRef = useRef({ startIndex: 0, endIndex: 0 });
  const { setIsFocused } = useFocusedFlightLegDispatch();
  const { isFocused, focusedFlightLeg } = useFocusedFlightLegStore();
  const flightPuckRefToScroll = useFlightPuckRefStore(focusedFlightLeg?.flightLegKey);
  const [scrollWidth, setScrollWidth] = useState(0);
  const { state: userPreferencesState } = useUserPreferencesData();
  const { showFeature } = useFeatureFlag();
  const enableEnhancedScalingFlag = showFeature(Treatment.SCALING_BUTTON);
  const { ganttConfig } = useGanttConfig();
  const [clickedRowIndex, setClickedRowIndex] = useState(null);
  const selectedFlightDetails = useSelectedItemStoreWithEqualComparator(isEqual);

  const rowVirtualizer = useVirtualizer({
    // The total number of items to virtualize
    count: count,
    // The scrolling container
    getScrollElement: () => scrollElementRef.current,
    // Function, given an index, returns the estimated height or width of the item
    estimateSize: estimateRowHeightFn,
    // Function, given an index, returns a unique key
    getItemKey: getItemKeyFn,
    // Number of items to render above and below the visible area
    overscan: 25,
    // Initial scrollTop value
    initialOffset: scrollToOffset,
    // Returns the indexes of the visible rows
    rangeExtractor: useCallback((range) => {
      visibleRangeRef.current = { startIndex: range.startIndex, endIndex: range.endIndex };
      return defaultRangeExtractor(range);
    }, []),
  });

  useEffect(() => {
    rowVirtualizer.measure();
  }, [
    showRows,
    userPreferencesState?.ganttViewScaling.numberOfAircrafts,
    userPreferencesState?.puckSize?.puckSize,
    estimateRowHeightFn,
  ]);

  useEffect(() => {
    if (!prevRef && scrollElementRef?.current) {
      // The scrolling container ref may be null on first render,
      // so we need to remeasure the virtualizer once the ref has been
      // attached to the scrolling container
      rowVirtualizer.measure();
    }
  }, [scrollElementRef, prevRef, rowVirtualizer]);

  useEffect(() => {
    // Likely vertical scaling has changed, so we need to remeasure
    rowVirtualizer.measure();
    // Keeps the first row in view
    rowVirtualizer.scrollToIndex(visibleRangeRef.current.startIndex, { align: 'start', smoothScroll: false });
  }, [rowVirtualizer]);

  useEffect(() => {
    if (id == 'gantt' && scrollToFocusedFlightLegIndex !== undefined) {
      rowVirtualizer.measure();
      setTimeout(() => {
        rowVirtualizer.scrollToIndex(scrollToFocusedFlightLegIndex, { align: 'center', smoothScroll: false });
      }, 0); // Add the setTimeout to ensure the scrollToIndex is called after the measure
    }
  }, [scrollToFocusedFlightLegIndex, rowVirtualizer]);

  const scrollElementIntoView = (element) => {
    if (element) {
      // Get the left position of the element
      const elementLeft = element.getBoundingClientRect()?.left;
      // Get the left scroll position of the bottom content
      const leftScrollPosition = scrollElementRef.current?.scrollLeft;
      // Get the offset width of the bottom content
      const offsetWidth = scrollElementRef?.current?.offsetWidth / 2;
      // absolute left position of the element
      const elementAbsoluteLeft = elementLeft + leftScrollPosition;
      // center of the element of Y axis
      const x_center = elementAbsoluteLeft > offsetWidth ? Math.abs(elementAbsoluteLeft - offsetWidth) : 0;
      // scrollTo center of the element vertically
      scrollElementRef.current?.scrollTo({
        left: x_center,
      });
    }
  };

  useEffect(() => {
    if (id === 'gantt' && focusedFlightLeg && flightPuckRefToScroll) {
      const currentScrollWidth = scrollElementRef.current?.scrollWidth;
      // If the focused flight leg is not in view, scroll to it
      if (!isFocused || scrollWidth !== currentScrollWidth) {
        // Scroll to the focused flight leg
        scrollElementIntoView(flightPuckRefToScroll);
        // Set the scrollWidth to the current scrollWidth
        setScrollWidth(currentScrollWidth);
      }
      if (!isFocused) {
        // Set focused flight leg store isFocused to true, so it doesn't scroll into focus again
        setIsFocused(true);
      }
    }
  }, [isFocused, focusedFlightLeg, flightPuckRefToScroll, setIsFocused]);

  useEffect(() => {
    let selectedAirCraft = selectedFlightDetails?.data?.aircraft;
    const ganttConfigArray = Object.entries(ganttConfig);
    for (let i = 0; i < ganttConfigArray.length; i++) {
      if (ganttConfigArray[i][0] === selectedAirCraft) {
        setClickedRowIndex(i);
      }
    }
  }, [selectedFlightDetails?.data?.aircraft]);

  return (
    <div
      className={`inner-${id}-content`}
      data-cy={!!dataCyTag ? dataCyTag : 'gantt-chart-virtualizer'}
      style={{
        height: `${rowVirtualizer.getTotalSize()}px`,
        position: 'relative',
      }}
    >
      {rowVirtualizer.getVirtualItems().map((virtualRow) => (
        <div
          key={`virtual-${id}-row-${virtualRow.index}`}
          className={`virtual-${id}-row-${virtualRow.index}`}
          data-cy={`virtual-${id}-row-${virtualRow.index}`}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            transform: `translateY(${virtualRow.start}px)`,
            height: `${virtualRow.size}px`,
            zIndex: enableEnhancedScalingFlag && clickedRowIndex === virtualRow.index ? '9' : '',
          }}
        >
          {renderFn(virtualRow.key)}
        </div>
      ))}
    </div>
  );
};

export default GanttChartVirtualizer;

GanttChartVirtualizer.propTypes = {
  count: PropTypes.number.isRequired,
  renderFn: PropTypes.func.isRequired,
  estimateRowHeightFn: PropTypes.func.isRequired,
  getItemKeyFn: PropTypes.func.isRequired,
  scrollToOffset: PropTypes.number,
  scrollElementRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  dataCyTag: PropTypes.string,
  scrollToFocusedFlightLegIndex: PropTypes.number, // This prop is optional
};
